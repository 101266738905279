<template>
    <div>
        <ek-table
            :items="questionAnswerList"
            :columns="columns"
            @details="questionDetail"
            @delete-selected="deleteQuestion"
        >
            <template slot="items.dateCreated" slot-scope="{ value }"
                ><span>{{ new Date(value).toLocaleDateString() }}</span>
            </template>
            <template slot="items.isBlocked" slot-scope="{ value }">
                <StatusBadge
                    :options="questionStatusList"
                    :value="value"
                ></StatusBadge>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";
export default {
    components: {
        StatusBadge
    },
    computed: {
        ...mapState({
            questionsList: state => state.questionsAnswerer.questionsList,
            columns: state => state.questionsAnswerer.columns,
            questionStatusList: state => state.questionsAnswerer.questionStatus
        }),
        ...mapGetters(["questionAnswerList"])
    },
    methods: {
        ...mapActions(["getAllQuestionsAnswerer", "deleteQuestionsAnswerer"]),
        questionDetail({ row }) {
            this.$router.push(`/admin/questionsAnswerer/${row.id}`);
        },
        deleteQuestion(e) {
            this.deleteQuestionsAnswerer(e);
        }
    },
    created() {
        this.getAllQuestionsAnswerer();
    }
};
</script>

<style></style>
